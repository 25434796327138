import React from 'react';

function WhyUs() {
    return (
        <section id="why-us" className="why-us section-bg">
            <div className="container-fluid" data-aos="fade-up">

                <div className="row">

                    <div className="col-lg-12 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                        <div className="content">
                            <h3><strong>The Ethics</strong> That We Follow</h3>
                            <p>
                            BlissLord Technologies is supported by technical experts who have a deep understanding of the business sector and are skilled at producing high-quality solutions.
                            </p>
                        </div>

                        <div className="accordion-list">
                            <div className="row col-lg-12">
                            <div className="col-lg-6">
                            <ul>
                                <li className='text-start'>
                                    <a data-bs-toggle="collapse" className="collapsed" data-bs-target="#accordion-list-1">
                                        <span>01</span> CLIENT ORIENTED APPROACH 
                                        <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                    <div id="accordion-list-1" className="collapse" data-bs-parent=".accordion-list">
                                        <p>
                                        It has always been our prime concern to give utmost satisfaction to our clients with best of our Talents,
                                         Potentials, Knowledge, and above all our Team work.
                                        </p>
                                    </div>
                                </li>
                                <li className='text-start'>
                                    <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" className="collapsed">
                                        <span>03</span> TIME & STANDARDS
                                         <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                    <div id="accordion-list-3" className="collapse" data-bs-parent=".accordion-list">
                                        <p>
                                        Timely completion of every project and a standardized pattern of work is something what we follow 
                                        considering the elements like proper management, and maintaining economy at every phase.
                                        </p>
                                    </div>
                                </li>
                                <li className='text-start'>
                                    <a data-bs-toggle="collapse" data-bs-target="#accordion-list-5" className="collapsed">
                                        <span>05</span> WORKING CLIMATE
                                        <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                    <div id="accordion-list-5" className="collapse" data-bs-parent=".accordion-list">
                                        <p>
                                        BlissLord has got a very friendly and learning environment for everyone with freedom 
                                        to express himself in respect of his research or invention or suggestion.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            </div>
                            <div className="col-lg-6">
                            <ul>
                                <li className='text-start'>
                                    <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" className="collapsed">
                                        <span>02</span> RESULT-ORIENTATION
                                        <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                    <div id="accordion-list-2" className="collapse" data-bs-parent=".accordion-list">
                                        <p>
                                        We set the goals Not That We Like but the ones That Are Result Driven and that let us more focused on what we do.
                                        </p>
                                    </div>
                                </li>
                                <li className='text-start'>
                                    <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4" className="collapsed">
                                        <span>04</span> QUALITY ORIENTATION
                                         <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                    <div id="accordion-list-4" className="collapse" data-bs-parent=".accordion-list">
                                        <p>
                                        We set the things right with our intuitive way of working and maintaining 
                                        the optimum quality standards with intent to learn something new and to implement it.
                                        </p>
                                    </div>
                                </li>
                                <li className='text-start'>
                                    <a data-bs-toggle="collapse" data-bs-target="#accordion-list-6" className="collapsed">
                                        <span>06</span> BUSINESS POLICY
                                        <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                    <div id="accordion-list-6" className="collapse" data-bs-parent=".accordion-list">
                                        <p>
                                        Best business practices in respect of safety and security of Projects 
                                        and Data -NDA with Clients as well as Employees of our company.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" data-aos="zoom-in" data-aos-delay="150">&nbsp;</div>
                </div>

            </div>
        </section>
    )
}
export default WhyUs;