import React from 'react';
import{ CompanyName,FooterContent } from '../../constants/common';

function Footer() {
    return (
        <footer id="footer">

            {/* <div className="footer-newsletter">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <h4>{FooterContent.JoinOurNewsletter}</h4>
                            <p>{FooterContent.JoinOurNewsletterContent}</p>
                            <form action="" method="post">
                                <input type="email" name="email" /><input type="submit" value={FooterContent.Subscribe} />
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="footer-top">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 col-md-6 footer-contact text-start">
                            
                            <p className="mt-35 ">
                                {FooterContent.AboutUs} <br />
                                <strong>{FooterContent.Phone}</strong> {FooterContent.PhoneNumber}<br />
                                <strong>{FooterContent.Email}</strong> {FooterContent.EmailAddress}<br />
                            </p>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links text-start">
                            <h4 className='ml-20'>{FooterContent.UsefulLinks}</h4>
                            <ul>
                                {FooterContent.UsefulLinksArray.map(x=>{return <li key={x.name}><i className="bx bx-chevron-right"></i> <a href={x.redirectComponent}>{x.name}</a></li>})}
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links text-start">
                            <h4  className='ml-20' >{FooterContent.OurServices}</h4>
                            <ul>
                                {FooterContent.OurServicesArray.map(x=>{return <li key={x.name}><i className="bx bx-chevron-right"></i> <a href="#">{x.name}</a></li>})}
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links text-start">
                            <h4>{FooterContent.OurSocialNetworks}</h4>
                            <p>{FooterContent.OurSocialNetworksContent}</p>
                            <div className="social-links mt-3">
                                <a href={FooterContent.LinkedinLink} className="linkedin"><i className="bx bxl-linkedin"></i></a>
                                <a href={FooterContent.InstagramLink} className="instagram"><i className="bx bxl-instagram"></i></a>
                                <a href={FooterContent.FacebookLink} className="facebook"><i className="bx bxl-facebook"></i></a>
                                <a href={FooterContent.TwitterLink} className="twitter"><i className="bx bxl-twitter"></i></a>
                                <a href={FooterContent.SkypeLink} className="google-plus"><i className="bx bxl-skype"></i></a>
                                
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="container footer-bottom clearfix">
                <div className="copyright">
                    &copy; {FooterContent.CopyRight} <strong><span>{CompanyName}</span></strong>. {FooterContent.AllRightsReserved}
                </div>
                <div className="credits">

                </div>
            </div>
        </footer>
    )
}
export default Footer;