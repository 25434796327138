import React from 'react';

function Service() {
    return (
        <section id="services" className="services section-bg">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>Services</h2>
                    <h3><strong>Taking your Digital Business onto a never-ending success journey!</strong></h3>
                    <p className="mb-2">BlissLord offers high-end digital solutions for a plethora of digital services falling under these requirement ranges for all of its clients</p>
                </div>

                <div className="row">
                    <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-desktop"></i></div>
                            <h4><a>Custome Software Development</a></h4>
                            <p>Unlock tailored solutions for your unique needs. Our custom software development expertise empowers your business with innovation and efficiency.</p>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-terminal"></i></div>
                            <h4><a>Web Development</a></h4>
                            <p>Elevate your online presence with our expert web development solutions. Let's craft your digital success story together.</p>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-mobile"></i></div>
                            <h4><a>Mobile App Development</a></h4>
                            <p>Crafting brilliance for the mobile world. Our mobile app development transforms ideas into captivating experiences, driving innovation to your fingertips.</p>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
                        <div className="icon-box">
                            <div className="icon"><i className='bx bx-shopping-bag'></i></div>
                            <h4><a>E-commerce Development</a></h4>
                            <p>Revolutionize your online presence. Our E-commerce Development expertise crafts tailored solutions, ensuring your business thrives in the digital marketplace.</p>
                        </div>
                    </div>

                </div>
                <div className="row mt-4">
                    <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                        <div className="icon-box">
                            <div className="icon">
                            <i className='bx bxs-image-add'></i>
                            </div>
                            <h4><a>UI/UX Development</a></h4>
                            <p>Transform user experiences into memorable journeys with our innovative UI/UX development. Elevate your digital presence with intuitive design.</p>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                        <div className="icon-box">
                            <div className="icon"> <i className='bx bx-file-find' ></i></div>
                            <h4><a>SEO</a></h4>
                            <p>Drive organic growth and dominate search rankings with our unparalleled SEO expertise. Supercharge your online presence and stay ahead in the digital landscape</p>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-cloud"></i></div>
                            <h4><a>Cloud & DevOps</a></h4>
                            <p>Navigate the cloud with confidence and streamline operations through our expert Cloud & DevOps solutions. Harness the power of efficiency and scalability for your digital journey.</p>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-store"></i></div>
                            <h4><a>Digital Marketing</a></h4>
                            <p>Amplify your online presence with our results-driven digital marketing strategies. Let's craft a compelling narrative that resonates with your audience and drives success.</p>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    )
}
export default Service;