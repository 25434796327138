import React, { useRef,useState } from 'react';
import emailjs from '@emailjs/browser';

function Contact() {
    const form = useRef();
    const [errorMsg, setErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const sendEmail = (e) => {
    e.preventDefault();
    if(!form.current.message.value || !form.current.subject.value || !form.current.email.value || !form.current.name.value){
        setErrorMsg("*All fields are required");
    }
    else{
        emailjs.sendForm('service_jnsvoxd', 'template_31finxe', form.current, 'a4EgHrNrOWOjU6iNH')
          .then((result) => {
            form.current.reset();
            setErrorMsg("");
            setSuccessMsg("Your message has been sent. Thank you!");
          }, (error) => {
             
          });
      };
    }

    return (
        <section id="contact" className="contact">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>Contact</h2>
                    <p>Want to gett in touch? We'd love to hear from you. Here's how you can reach us...</p>
                </div>
                    {/* <div className="col-lg-5 d-flex align-items-stretch">
                        <div className="info">
                            <div className="address">
                                <i className="bi bi-geo-alt"></i>
                                <h4>Location:</h4>
                                <p>A108 Adam Street, New York, NY 535022</p>
                            </div>

                            <div className="email">
                                <i className="bi bi-envelope"></i>
                                <h4>Email:</h4>
                                <p>blisslordtechnologies@gmail.com</p>
                            </div>

                            <div className="phone">
                                <i className="bi bi-phone"></i>
                                <h4>Call:</h4>
                                <p>+1 5589 55488 55s</p>
                            </div>

                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" allowfullscreen></iframe>
                        </div>

                    </div> */}

                    <div className="d-flex align-items-center">
                        <form ref={form} role="form" className="php-email-form">
                            <div className="row">
                                <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="name">Your Name</label>
                                    <input type="text" name="name" className="form-control" id="name" required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="name">Your Email</label>
                                    <input type="email" className="form-control" name="email" id="email" required />
                                </div>
                            
                            <div className="form-group">
                                <label htmlFor="name">Subject</label>
                                <input type="text" className="form-control" name="subject" id="subject" required />
                            </div>
                            </div>
                            <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="name">Message</label>
                                <textarea className="form-control" name="message" rows="10" required></textarea>
                            </div>
                            </div>
                            </div>
                           
                            {/* <div className="my-3">
                                <div className="loading">Loading</div>
                                <div className="error-message">{errorMsg}</div>
                                <div className="sent-message">Your message has been sent. Thank you!</div>
                            </div> */}
                            {errorMsg && <div className="error-message">{errorMsg}</div>}
                            {successMsg && <div className="sent-message">{successMsg}</div>}
                            <div className="text-center"><button type="submit" onClick={sendEmail}>Send Message</button></div>
                        </form>
                    </div>
            </div>
        </section>
    )
}
export default Contact;