import React from 'react';

function About() {
    return (
        <section id="about" className="about">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>About Us</h2>
                </div>

                <div className="row content">
                       <h3><strong>A Hub for End to End Software Solutions</strong></h3> 
                    <div className="col-lg-8 pt-4 pt-lg-0 mb-5 text-start">
                        <p>
                        The need to innovate never stops for today’s fast-moving generation of business. 
                        BlissLord transforms businesses by shifting from on-premise to cloud-based digital solutions 
                        that meet customer demands. We have been innovating the most dynamic software applications 
                        for a long time now and hundreds of our successful projects in different business niches are proof. 
                        Our core team of expert tech professionals dive into the project requirements and use the best of 
                        their skills to create your legacy software.
                        </p>
                        {/* <a href="#" className="btn-learn-more">Learn More</a> */}
                    </div>
                    <div className="col-lg-4 text-start">
                        
                        <ul>
                            <li><i className="ri-check-double-line"></i> We convert your idea in to reality</li>
                            <li><i className="ri-check-double-line"></i> We Always Analyze The Market</li>
                            <li><i className="ri-check-double-line"></i> We Have The Most Brilliant Ideas</li>
                            <li><i className="ri-check-double-line"></i> We Always Make Successful Projects</li>
                        </ul>
                    </div>
                </div>

            </div>
        </section>
    )
}
export default About;