import logo from './logo.svg';
import './App.css';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Banner from './components/header/Banner';
import Client from './components/main/Client';
import About from './components/main/About';
import WhyUs from './components/main/WhyUs';
import Skill from './components/main/Skill';
import Service from './components/main/Service';
import CallToAction from './components/main/CallToAction';
import Portfolio from './components/main/Portfolio';
import Team from './components/main/Team';
import Pricing from './components/main/Pricing';
import FAQ from './components/main/FAQ';
import Contact from './components/main/Contact';

function App() {
  return (
    <div className="App">
      <Header />
      <Banner />

      <main id="main">
        {/* <Client /> */}
        <About />
        <WhyUs />
        <Skill />
        <Service />
        {/* <CallToAction /> */}
        {/* <Portfolio /> */}
        {/* <Team /> */}
        {/* <Pricing /> */}
        {/* <FAQ /> */}
        <Contact />
      </main>

      <Footer />

      {/* <div id="preloader"></div> */}
      <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
    </div>
  );
}

export default App;
