export const CompanyName="BlissLord Technologies";
export const HeaderContent={

};
export const FooterContent={
UsefulLinks:"Useful Links",
UsefulLinksArray:[{
    name:"Home",redirectComponent:"#"
},
{
    name:"About us",redirectComponent:"#about"
},
{
    name:"Services",redirectComponent:"#services"
},
{
    name:"Contact",redirectComponent:"#contact"
}
// {
//     name:"Terms of service",redirectComponent:""
// },
// {
//     name:"Privacy policy",redirectComponent:""
// }
],
OurServices:"Our Services",
OurServicesArray:[{
    name:"Custome Software Development",redirectComponent:""
},
{
    name:"Mobile App Development",redirectComponent:""
},
{
    name:"Cloud & DevOps",redirectComponent:""
},
{
    name:"Digital Marketing",redirectComponent:""
},
// {
//     name:"Graphic Design",redirectComponent:""
// }
],
JoinOurNewsletter:"Join Our Newsletter",
JoinOurNewsletterContent:"Tamen quem nulla quae legam multos aute sint culpa legam noster magna",
Subscribe:"Subscribe",
AboutUs:"We offer a broad range of software development and consulting services for digital transformation that make use of cutting-edge engineering techniques",
Phone:"Phone:",
PhoneNumber :"+91 93163 85113",
Email:"Email:",
EmailAddress:"blisslordtechnologies@gmail.com",
OurSocialNetworks:"Our Social Networks",
OurSocialNetworksContent:"Stay updated, engaged, and connected with BlissLord Technologies across various social media platforms. Follow us for the latest news, insights, and behind-the-scenes glimpses into our world.",
TwitterLink:"",
FacebookLink:"",
InstagramLink:"https://www.instagram.com/blisslord_technologies/",
SkypeLink:"",
LinkedinLink:"https://www.linkedin.com/company/blisslord/",
CopyRight:"Copyright",
AllRightsReserved:"All Rights Reserved"
};

