import React from 'react';

function Skill() {
    return (
        <section id="skills" className="skills">
            <div className="container" data-aos="fade-up">

                <div className="row">
                    <div className="col-lg-6 d-flex align-items-center" data-aos="fade-right" data-aos-delay="100">
                        <img src="assets/img/skills.png" className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0 content text-start" data-aos="fade-left" data-aos-delay="100">
                        <h3>Mission</h3>
                        <p>
                        At BlissLord Technologies, our mission is to empower businesses through innovative software solutions. 
                        We are dedicated to delivering cutting-edge technology that enhances efficiency, streamlines processes, 
                        and transforms ideas into reality. Committed to excellence, we strive to be a catalyst for our clients' 
                        success by providing reliable, scalable, and future-ready software solutions.
                        </p>
                        <h3>Vision</h3>
                        <p>
                        Our vision at BlissLord Technologies is to be a leading force in the realm of software development, 
                        recognized for our unwavering commitment to quality, creativity, and client satisfaction. 
                        We aspire to foster a culture of continuous innovation, where our team thrives on pushing boundaries and 
                        creating solutions that redefine the possibilities of technology. Through our vision, 
                        we aim to be the go-to partner for businesses seeking digital transformation and unparalleled software excellence.
                        </p>
                        {/* <div className="skills-content">

                            <div className="progress">
                                <span className="skill">Custome Software Development<i className="val">100%</i></span>
                                <div className="progress-bar-wrap">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>

                            <div className="progress">
                                <span className="skill">Mobile App Development<i className="val">90%</i></span>
                                <div className="progress-bar-wrap">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>

                            <div className="progress">
                                <span className="skill">Cloud & DevOps<i className="val">75%</i></span>
                                <div className="progress-bar-wrap">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>

                            <div className="progress">
                                <span className="skill">Digital Marketing<i className="val">55%</i></span>
                                <div className="progress-bar-wrap">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>

                        </div> */}

                    </div>
                </div>

            </div>
        </section>
    )
}
export default Skill;